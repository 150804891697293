<template>
  <MasterDetail
    formTitle="Cadastro de Folhas de Pagamento"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    resourceListProperty="fopags"
    descriptionProperty="id"
    :actionBarButtons="actionBarButtons"
    :contextOptions="options"
    has-year-filter
  >
    <v-menu
        offset-y        
        left
        dense  
      >
      <template #activator="{on, attrs}">
        <div
          v-bind="attrs"
          v-on="on"
          class="table-v-action-button mr-2"
          style="cursor: pointer"
        >
          <v-icon >mdi-import</v-icon>
          Importações
        </div>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title class="v-label-input"
            v-if="hasFopagPermission"
            style="cursor: pointer"
            @click="routerPush('listagem-importacoes-fopag', tipoImportacao.rhFopag)"
          >
            <v-icon left>mdi-file-upload-outline</v-icon>
            Importar FOPAG
          </v-list-item-title>       
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="v-label-input"
            v-if="hasProvisaoPermission"
            style="cursor: pointer"
            color="primary"
            depressed
            dark            
            @click="routerPush('listagem-importacoes-provisoes', tipoImportacao.rhProvisoes)"
          >
            <v-icon left>mdi-book-arrow-up-outline</v-icon>
            Importar provisões
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="v-label-input"
            v-if="hasPontoPermission"
            style="cursor: pointer"
            color="primary"
            depressed
            dark
            @click="routerPush('listagem-importacoes-ponto', tipoImportacao.rhPonto)"
          >
            <v-icon left>mdi-file-clock-outline</v-icon>
            Importar controle de ponto
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </MasterDetail>
</template>

<script>
import { mapGetters } from 'vuex';
import MasterDetail from "@/components/master-detail.vue";
import { TipoImportacaoEnum } from "@/core/enums/importacoes";

export default {
  components: {
    MasterDetail,
  },
  created: function () {
    this.funcionarioResource.get().then((response) => {
      this.opts.Funcionario = response.funcionario;
    });
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons: function () {
      const buttons = [
         {
          text: "Alocação de Horas",
          icon: "mdi-clock-time-eight-outline",
          show: this.userHasAccess("Timesheet.alocacaoDeHoras.index"),
          action: () => {
            this.$router.push({ name: "timesheets", query: { funcionarioId: this.colaboradorId }});
          },
        },
        {
          text: "Dispêndio",
          icon: "mdi-calculator-variant-outline",
          show: this.userHasAccess("Dispendios.recursosHumanos.index"),
          action: () => {
            this.$router.push({ name: "dispendio-rh", query: { colaboradorId: this.colaboradorId }});
          },
        }
      ];

      return buttons.filter(({ show }) => show);
    },
    colaboradorId: function () {
      return this.$route.params.colaboradorId;
    },
    funcionarioResource: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/colaboradores/${this.colaboradorId}`);
    },
    options: function() {
      return this.userHasAccess("Colaboradores.folhaDePagamento.index") ? [
        {
          name: "Ver Rubricas",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({ name: "itens-folha-pagamento-colaborador", params: { colaboradorId: this.colaboradorId, fopagId: row.id }});
          },
        },
      ] : [];
    },
    resourceUrl: function () {
      return `/v1/rh/${this.clientId}/colaboradores/${this.colaboradorId}/fopag`;
    },
    hasPontoPermission: function() {
      return this.userHasAccess("Importacoes.recursosHumanos.controleDePonto")
    },
    hasProvisaoPermission: function() {
      return this.userHasAccess("Importacoes.recursosHumanos.provisoes")
    },
    hasFopagPermission: function() {
      return this.userHasAccess("Importacoes.recursosHumanos.folhaDePagamento")
    }
  },
  data: function () {
    return {
      cols: [
        { key: "id", name: "FOPAG ID", align: 1, hideInform: true, type: 0 },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
        },
        {
          key: "status",
          name: "Status Cálculo LB",
          editable: false,
          hideInform: true,
          rel: { to: "status", key: "id", name: "nome" },
          type: this.$fieldTypes.SELECT,
        },
        {
          key: "statusLI",
          name: "Status Cálculo LI",
          editable: false,
          hideInform: true,
          rel: { to: "status", key: "id", name: "nome" },
          type: this.$fieldTypes.SELECT,
        },
        {
          key: "jobId",
          name: "ID Importação",
          hideInform: true,
          type: 0,          
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
        {
          key: "criado_em",
          name: "Data de Criação",
          align: 1,
          editable: false,
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
           hideInform: true,
        },
        
      ],
      opts: {
        Funcionario: [],
        status: [
          { id: 0, nome: "Novo" },
          { id: -1, nome: "Erro no Cálculo" },
          { id: 11, nome: "Cálculo Finalizado"},
          { id: 1, nome: "Calculada" },
          { id: 2, nome: "A recalcular" },
          { id: 102, nome: "Alterada - Rubrica Editada" },
          { id: 103, nome: "Alterada - Timesheet" },
          { id: 105, nome: "Alterada - De-Para Funcionário" },
          { id: 201, nome: "Alterada - Pareamento de Rubricas" },
          { id: 202, nome: "Alterada - Encargos" },
          { id: 301, nome: "Alterada - Lei do Projeto" },
          { id: 302, nome: "Alterada - Projeto Reprovado" },
          { id: 303, nome: "Alterada - Projeto Aprovado" },
          { id: 20, nome: "Calculando Ajuste" },
          { id: 30, nome: "Calculando Ajuste" }
        ],
      },
      tipoImportacao: {
        rhProvisoes: TipoImportacaoEnum.RH_PROVISOES,
        rhFopag: TipoImportacaoEnum.RH_FOPAG,
        rhPonto: TipoImportacaoEnum.RH_PONTO
      }
    };
  },
  methods: {
    routerPush: function(routeName, routeType){
      this.$router.push({ name: routeName, query: { tipo: routeType }})
    }
  },
  watch: {
    clientId: function () {
      this.$router.push({ name: "listagem-colaboradores" });
    },
  },
};
</script>
